<template>
  <div>
    <div class="right" style="padding-bottom: 2vh" v-if="!disabled">
      <a-button type="primary" @click="add">添加</a-button>
    </div>
    <a-table bordered :dataSource="list" :pagination="false">
      <a-table-column key="index" title="序号">
        <template slot-scope="text, row, index">
          {{ index + 1 }}
        </template>
      </a-table-column>
      <a-table-column key="code" title="资产编号" data-index="code" />
      <a-table-column key="name" title="资产名称" data-index="name" />
      <a-table-column key="brand" title="品牌" data-index="brand" />
      <a-table-column
        key="specification"
        title="规格型号"
        data-index="specification"
      />
      <a-table-column
        key="unit"
        title="单位"
        data-index="unit"
        align="center"
      />
      <a-table-column key="number" title="数量" align="right">
        <template slot-scope="text">
          <Money :money="text.number" />
        </template>
      </a-table-column>
      <a-table-column key="price" title="单价(元)" align="right">
        <template slot-scope="text">
          <Money :money="text.price" />
        </template>
      </a-table-column>
      <a-table-column key="priceTotal" title="总价(元)" align="right">
        <template slot-scope="text">
          <Money :money="text.priceTotal" />
        </template>
      </a-table-column>
      <a-table-column
        key="control"
        title="操作"
        align="center"
        v-if="!disabled"
      >
        <template slot-scope="text, row, index">
          <a-space>
            <a href="#" @click.prevent="edit(text, index)">编辑</a>
            <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
          </a-space>
        </template>
      </a-table-column>
    </a-table>

    <a-modal
      title="添加/编辑资产"
      :visible="visible"
      :footer="null"
      width="800px"
      @cancel="cancel"
    >
      <a-form :form="form" @submit="handleSubmit" :colon="false">
        <a-row :gutter="8">
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="资产编号">
              <a-input
                :disabled="true"
                v-decorator="[
                  'code',
                  {
                    initialValue: text.code,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="资产名称">
              <a-input
                v-decorator="[
                  'name',
                  {
                    initialValue: text.name,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="品牌">
              <a-input
                v-decorator="[
                  'brand',
                  {
                    initialValue: text.brand,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="规格型号">
              <a-input
                v-decorator="[
                  'specification',
                  {
                    initialValue: text.specification,
                  },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="单位">
              <a-input
                v-decorator="[
                  'unit',
                  {
                    initialValue: text.unit,
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="数量">
              <a-input-number
                style="width: 100%"
                v-decorator="[
                  'number',
                  {
                    initialValue: text.number,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="单价(元)">
              <a-input-number
                style="width: 100%"
                v-decorator="[
                  'price',
                  {
                    initialValue: text.price,
                    rules: [{ required: true, message: '请输入' }],
                  },
                ]"
              />
            </a-form-item>
          </a-col>
        </a-row>

        <div class="right">
          <a-space>
            <a-button @click="cancel">关闭</a-button>
            <a-button type="primary" html-type="submit"> 提交 </a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: this.$form.createForm(this),
      visible: false,
      text: {},
      index: -1,
    };
  },

  methods: {
    add() {
      this.visible = true;
    },
    edit(text, index) {
      this.text = text;
      this.index = index;
      this.visible = true;
    },
    remove(index) {
      this.list.splice(index, 1);
    },

    cancel() {
      this.visible = false;
      this.text = {};
      this.index = -1;
      this.form.resetFields();
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log(values);
          if (this.index !== -1) {
            this.list.splice(this.index, 1, {
              ...this.text,
              ...values,
              priceTotal: values.number * values.price,
            });
          } else {
            this.list.push({
              ...values,
              priceTotal: values.number * values.price,
            });
          }

          this.cancel();
        }
      });
    },
  },
};
</script>